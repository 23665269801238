import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';
import SimpleDateTime  from 'react-simple-timestamp-to-date';

const strings = new LocalizedStrings(localeFile);
class LatesNews extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
        this.state = {
            posts: []
          };
   
        lax.update(window.scrollY)
    }

    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_twocategory`)
          .then(res => {
            const posts = res.data.category;
            this.setState({ posts });
    
       
          })
      }
    render(){        strings.setLanguage(this.props.languageCode);

        return (
            <section className="about-area-two ptb-120 bg-image">
                <div className="container">
                    <div className="section-title">
                    {/* {strings.hreflangCode=="en"? 'he'} */}
                        {/* <span>{strings.Nos_dernières_nouvelles}</span> */}
                        <h2 className='mb-5'> <b>   {strings.ACTUALITÉS}</b></h2>
                     
                    

                        <Link to="/blog-1" className="btn btn-primary mt-3">{strings.Voir_toutes_les_nouvelles}</Link>

                        <div className="bar"></div>
                    </div>

                    <section className="blog-area bg-image">
                    <div className="container">
                        <div className="row">
                        
                            {     this.state.posts.length != 0 && (this.state.posts.map((category,index) => {
            return        category.posts.length != 0 && (category.posts.map((post,index) => {
            return     <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
                <div className="blog-image">
      

             {  post.post_images.length > 0 && ( post.post_images.map((im,index) => {
            return <img src={im.presentation=='1'?"https://admin.futurevisions.tn/"+im.image :""}  />}))}
                    <div className="post-tag">
                        <Link to="#">{strings.hreflangCode=='fr'?    category.name :  category.name_arabe}</Link>
                    </div>
                </div>

                <div className="blog-post-content">
                    <span className="date"><SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{post.updated_at}</SimpleDateTime></span>
                    <h3>                        <Link to={`/single-blog/id=${post.id}`}>{ strings.hreflangCode=='fr'?   post.titre : post.titre_arabe }</Link>
</h3>
                    <p>{ strings.hreflangCode=='fr' ?post.mini_description :post.mini_description_arabe}</p>
                    <Link  to={`/single-blog/id=${post.id}`}className="read-more-btn">{strings.En_savoir_plus}{strings.hreflangCode=='fr'?  <i className="icofont-double-right"> </i>: <i className="icofont-double-left"> </i>}</Link>
                </div>
            </div>
        </div>         
          })) }))}               

                        </div>
                    </div>
                </section>
                </div>
            </section>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(LatesNews);