import React, {Component} from 'react';

import logo from '../../assets/images/logo visio-09.png';

import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {NavLink,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import LanguageSwitch from '../../LanguageSwitch/LanguageSwitch';

const strings = new LocalizedStrings(localeFile);

class NavBar extends Component {

  state = {

    collapsed: true,
    isOpen: false
  }
  toggleNavbar = () => {
    this.setState({
        collapsed: !this.state.collapsed,
    });
}
componentDidMount() {
  let elementId = document.getElementById("navbar");
  document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
          elementId.classList.add("is-sticky");
          window.history.pushState("", document.title, window.location.pathname);
      } else {
          elementId.classList.remove("is-sticky");
      }
  });
  window.scrollTo(0, 0);
}

toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });


onRouteChanged = () => {
  this.setState({ isOpen: !this.state.isOpen });
}

  render() {
    strings.setLanguage(this.props.languageCode);
    const { collapsed } = this.state;
    const classOne = collapsed ? 'collapse navbar-collapse ' : 'collapse navbar-collapse show';
    const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;

    return(
<>

      <header id="header" className="header-area">
             
  <div id="navbar" className="elkevent-nav ">
    
           <nav className="navbar navbar-expand-md navbar-dark ">
             
                        <div className="container-fluid ">
                   
                        <Link className="navbar-brand "  to="/">
                                <img  className="logo" src={logo} alt="logo" />
                            </Link>   
                        <button 
                                 onClick={this.toggleNavbar}
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" 
                                aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                          
                            <div className={classOne} id="navbarSupportedContent">
                          
                            <ul className="navbar-nav ms-auto d-flex justify-content-start">
                                    <li className="nav-item">
                                    <NavLink 
                                                    to="/home" 
                                                    className="nav-link" 
                                                 
                                                >
                                                    {strings.Accueil}
                                                </NavLink>

                                    </li>
                                    
                                    <li className="nav-item">
                                    <NavLink 
                                                            to="/about-2" 
                                                            className="nav-link" 
                                                          
                                                        >
                                                           {strings.a_propos}

                                                        </NavLink>
                                        
                               
                                    </li>
                                    <li className="nav-item">
                                    <NavLink 
                                                            to="/about-22" 
                                                            className="nav-link" 
                                                          
                                                        >
                                                           {strings.Round}

                                                        </NavLink>
                                        
                               
                                    </li>
                                    <li className="nav-item">
                                    <NavLink 
                                                    to="/organisations" 
                                                    className="nav-link"
                                                  
                                                > {strings.ORGANISATIONS}

                                                </NavLink>
                                    
                                    </li>

                              
                                 

                                    <li className="nav-item">
                           
                                                <NavLink 
                                                    to="/blog-1" 
                                                    className="nav-link" 
                                                  
                                                >
                                                                                                    {strings.ACTUALITÉS}

                                                </NavLink>
                                            </li>
                                            <li className="nav-item">
                                    <NavLink 
                                                    to="/Ressources" 
                                                    className="nav-link" 
                                                  
                                                >
                                                    {strings.RESSOURCES}
                                                </NavLink>
                                        
                                    </li>

                                            <li className="nav-item">
                           
                           <NavLink 
                               to="/contact" 
                               className="nav-link" 
                             
                           >
                                                                               {strings.Contact}

                           </NavLink>
                       </li>

                           
                                </ul>
                             
                                    <ul>
                                        <li className='mr-5' >
                                                            <LanguageSwitch  urlSuffix={['', '']} />

                                                
                                          

                                        </li>
                                    </ul>
                              
                            </div>
                        </div>
                    </nav>
                  
                    </div>
            </header>
      </>

    );
  }
}

const mapStateToProps = state => {
  return {
    languageCode: state.languageCode
  }
}

export default connect(mapStateToProps)(NavBar);
