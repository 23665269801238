import lax from 'lax.js';
import MainBanner from '../HomeTwo/MainBanner';
import About from '../HomeTwo/About';
import WhyUs from '../Common/WhyUs';
import Organisations from '../HomeTwo/Organisations';
import LatesNews from '../HomeTwo/LatestNews';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
import React, {useEffect , useState} from 'react'
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
import Contact from '../HomeTwo/Contact';
import EventSchedulesOne from '../schedule/EventSchedulesOne';
import Round from '../HomeTwo/Round';
const strings = new LocalizedStrings(localeFile);


 



class HomeTwo extends React.Component {
    
    render() {
  
      strings.setLanguage(this.props.languageCode);
  
      return(
        <React.Fragment>
               
        <MainBanner />
      
        <About />
        <Round/>
        <WhyUs />

        <EventSchedulesOne/>

        <LatesNews />
        <Organisations />

          <Contact/>
        <Footer />
      
        <GoTop scrollStepInPx="50" delayInMs="16.66" />
    </React.Fragment>
      );
    }
  }
  
  const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(HomeTwo);