import React from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';

const strings = new LocalizedStrings(localeFile);

class MainBanner extends React.Component {

    
    render(){      strings.setLanguage(this.props.languageCode);

        return (
            <div className="page-title-area item-bg5">
                <div className="container">
                    <h1> {strings.Organisations_Members}</h1>
                    {/* <span>One Team, One Dream</span> */}
                    <ul>
                        {/* <li><Link to="/">{strings.Accueil}</Link></li>
                        <li>{strings.ORGANISATIONS}</li> */}
                    </ul>
                </div>
            </div>
        );
    }
}
 const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(MainBanner);