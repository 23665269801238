import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class WhyUs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            definitions: []
          };
        console.log(props)
    }
    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_definitions`)
          .then(res => {
            const definitions = res.data.definition;
            this.setState({ definitions });
       
          })
      }
    render(){   strings.setLanguage(this.props.languageCode);
        return (
            <section className="why-choose-us">
                <div className="row m-0">


                    {/* <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-headphone-alt-1"></i>
                                        </div>
                                        <h3>Defintion 1</h3>
                                        <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-network-tower"></i>
                                        </div>
                                        <h3>Defintion 2</h3>
                                        <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                            <i className="icofont-users-social"></i>
                                        </div>
                                        <h3>Defintion 3</h3>
                                        <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
            
                                        <Link to="#" className="btn btn-primary">Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
   {
          // hna 3mlna boucle bach n7to data dyalna f table bootstrap 
          this.state.definitions.length > 0 && (this.state.definitions.map((definition,index) => {
            return              <div className="col-lg-3 col-sm-6 p-0">
            <div className="single-box">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="content">
                            <div className="icon">
                                <i className="icofont-wink-smile"></i>
                            </div>
                            <h3>{strings.hreflangCode=='fr'? definition.title : definition.title_arabe}</h3>
                            <p>{ strings.hreflangCode=='fr'? definition.description:definition.description_arabe}</p>

                            {/* <Link to="#" className="btn btn-primary">Read More</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
          }))


        }
       
                </div>

                <ul className='slideshow'>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </ul>
            </section>
        );
    }
}
 


  
const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(WhyUs);