import React from 'react'
import MainBanner from '../AboutTwo2/MainBanner';
import About from '../HomeTwo/About2';
import WhyUs from '../AboutTwo2/WhyUs';
import Organisations from '../HomeTwo/Organisations';
import Gallery from '../Common/Gallery2';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
import About2 from '../HomeTwo/About2';
 
class AboutTwo2 extends React.Component {
    render(){
        return (
            <React.Fragment>

                <MainBanner />

                <About/>

                <WhyUs />
         
                {/* <Organisations /> */}
         
                <Gallery />
            
                <Footer />
                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default AboutTwo2;