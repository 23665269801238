import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Common/Footer';
import axios from 'axios';
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class BlogOne extends React.Component {
    constructor(props) {
        super(props)
       
        this.state = {
            posts: []
          };
     
    }

    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_categories`)
          .then(res => {
            const posts = res.data.category;
            this.setState({ posts });
  
       
          })
      }
    render(){strings.setLanguage(this.props.languageCode);
        return (
            <React.Fragment>
                <div className="page-title-area item-bg2">
                    <div className="container">
                        <h1>{strings.ACTUALITÉS}</h1>
                        <span>{strings.Nos_dernières_nouvelles}</span>
                        <ul>
                            {/* <li><Link to="/">{strings.Accueil}</Link></li>
                            <li>{  strings.ACTUALITÉS}</li> */}
                        </ul>
                    </div>
                </div> 

                <section className="blog-area ptb-120 bg-image">
                    <div className="container">
                        <div className="row">
                        
                            {     this.state.posts.length != 0 && (this.state.posts.map((category,index) => {
            return        category.posts.length != 0 && (category.posts.map((post,index) => {
            return     <div className="col-lg-4 col-md-6">
            <div className="single-blog-post">
                <div className="blog-image">
      

             {  post.post_images.length > 0 && ( post.post_images.map((im,index) => {
            return <img src={im.presentation=='1'?"https://admin.futurevisions.tn/"+im.image :""}  />}))}
                    <div className="post-tag">
                        <Link to="#">{strings.hreflangCode=='fr'?    category.name :  category.name_arabe}</Link>
                    </div>
                </div>

                <div className="blog-post-content">
                    <span className="date"><SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{post.updated_at}</SimpleDateTime></span>
                    <h3>                        <Link to={`/single-blog/id=${post.id}`}>{ strings.hreflangCode=='fr'?   post.titre : post.titre_arabe }</Link>
</h3>
                    <p>{ strings.hreflangCode=='fr' ?post.mini_description :post.mini_description_arabe}</p>
                    <Link  to={`/single-blog/id=${post.id}`}className="read-more-btn">{strings.En_savoir_plus}{strings.hreflangCode=='fr'?  <i className="icofont-double-right"> </i>: <i className="icofont-double-left"> </i>}</Link>
                </div>
            </div>
        </div>         
          })) }))}               

                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}
 
 const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(BlogOne);