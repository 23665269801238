import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import OwlCarousel from 'react-owl-carousel3';
import { BsArrowLeftCircleFill } from "react-icons/bs";

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);

class Organisations extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
        this.state = {
            associations: [],
            imagepresentation: ('')
          };
    
        lax.update(window.scrollY)
    }
    

    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/gettow_associations`)
          .then(res => {
            const associations = res.data.Associations;
            this.setState({ associations });
       
       
          }).catch(({response}) => {
     
         
        })

 //   console.log(response.data.association.associaition_videos.length)
          

    }
 
      
    render(){      strings.setLanguage(this.props.languageCode);
      const options = {
        loop:true,
        nav: true,
        margin:8 ,  
         startPosition:1 ,
        navText: [
            "<i class='icofont-circled-left '></i>",
            "<i class='icofont-circled-right'></i>"
        ],
        responsive: {
            0: {
                items:1,
            },
            400: {
                items:1,
            },
            576: {
                items:2,
            },
            768: {
                items:2,
            },
            1200: {
                items:3,
            }
        }
    }
        return (
            <section className="about-area-two  ptb-120 pb-0 bg-image ">
                <div className="container ">
                    <div className="section-title">
                      <div className='row pb-5'>
                        <div className='col-lg-10  '>
                        <h2 className='mb-3'><b>{strings.Organisations_Members}</b></h2></div>
                        <div className='col-lg-1 col-md-4 logo-orgonasation'> 
                        </div>
                        </div>
                        <span>{strings.ORGANISATIONSdescrip}</span>

                        <div className="bar"></div>
                        <Link to="/Organisations" className="btn btn-primary mt-3"> {strings.Voir_tous_les_membres}</Link>
                    </div>
               

                <div className=" ">
                <OwlCarousel  {...options}
className="platinum-partner-slides owl-carousel owl-theme carsoul"      startPosition={1} 
> 

                {
          // hna 3mlna boucle bach n7to data dyalna f table bootstrap 
          this.state.associations.length > 0 && (this.state.associations.map((association,index) => {
            return            <div className="mx-3">
          <div className="single-speakers-box">
          <Link to={`/Organisation/id=${association.id}`}> 
            {  association.associaition_images.length > 0 && (association.associaition_images.map((im,index) => {
            return       <div className="speakers-image ">
                             <img src={im.presentation=='1'?"https://admin.futurevisions.tn/"+im.image :""}  />                     </div>
}))}

                              <div className="speakers-content">
                              <h1>{strings.hreflangCode=='fr'? association.nom : association.nom_arabe}</h1>
                                  <span>{strings.hreflangCode=='fr'? association.ville : association.ville_arabe}</span>

                                  <ul className="social">
                                      <li>                   
                                      { association.Facebook!=null? <a href={association.Facebook} target="_blank"><i className="icofont-facebook"></i></a>:''}

                                      </li>
                                      <li>                        
                                      { association.Twitter!=null?  <a href={association.Twitter}  target="_blank"><i className="icofont-twitter"></i></a>:''}

                                      </li>
                                      <li>
                                      { association.Instagram!=null?      <a href={association.Instagram} target="_blank"><i className="icofont-instagram"></i></a>:''}

                                    
                                      </li>
                                      <li>
                                        { association.Linkedin!=null? <a href={association.Linkedin} target="_blank"><i className="icofont-linkedin"></i></a>:''}
                                      </li>
                                  </ul>
                              </div></Link>
                    </div>
        </div>
          }))


        }</OwlCarousel>
                </div> </div>
            </section>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(Organisations);