import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class WhyUs extends React.Component {
    constructor(props) {
        super(props)
      
        this.state = {
            def: []
          };
      
    }
  
    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_definitions`)
          .then(res => {
            const def = res.data.definition;
            this.setState({ def });
     
          })
      }
    render(){ strings.setLanguage(this.props.languageCode);
        return (
            <section className="why-choose-us-two">
                <div className="row m-0 h-100 align-items-center">

                    
                {          this.state.def.length != 0 && (this.state.def.map((def,index) => {
            return <>   <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-img ">
                <img src={"https://admin.futurevisions.tn/"+def.image }   alt="Why Us" />
            </div>
        </div>
 
        <div className="col-lg-4 col-md-6 p-0">
            <div className="why-choose-content">
                <h3>{strings.hreflangCode=='fr'?  def.title :def.title_arabe}</h3>
                <p>{strings.hreflangCode=='fr'? def.description :def.description_arabe}</p>
               
 
                <span>{"0"+index+1}</span>
            </div>
        </div>           </>  
          }))}
                    
                </div>
            </section>
        );
    }
}
 



const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(WhyUs);