import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class OrganisationsList extends React.Component {
    constructor(props) {
        super(props)
        
    

        this.state = {
            associations: [],
            imagepresentation: ('')
          };
      
    }

    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_associations`)
          .then(res => {
            const associations = res.data.Associations;
            this.setState({ associations });
 
       
          }).catch(({response}) => {
            // ida fama error chno n3mlo ! 
  
        })

 //   console.log(response.data.association.associaition_videos.length)
          

    }
 
    render(){  strings.setLanguage(this.props.languageCode);
        return (
            <section className="speakers-area-two ptb-120">
                <div className="container">
                    <div className="row">
                  
                        {
          // hna 3mlna boucle bach n7to data dyalna f table bootstrap 
          this.state.associations.length > 0 && (this.state.associations.map((association,index) => {
            return    <div className="col-lg-4 col-md-6 ">
            <a href={`/Organisation/id=${association.id}`}>   <div className="single-speakers-box">
                {  association.associaition_images.length > 0 && (association.associaition_images.map((im,index) => {
            return                       <div className="speakers-image">
        <Link to={`/Organisation/id=${association.id}`}>  <img src={im.presentation=='1'?"https://admin.futurevisions.tn/"+im.image :""} />          </Link>             </div>
}))}

                    <div className="speakers-content">
                    <h3>{strings.hreflangCode=='fr'? association.nom : association.nom_arabe}</h3>
                        <span>{strings.hreflangCode=='fr'? association.ville : association.ville_arabe}</span>

                        <ul className="social">
                            <li>                   
                             { association.Facebook!=null? <a href={association.Facebook} target="_blank"><i className="icofont-facebook"></i></a>:''}

                            </li>
                            <li>                        
                            { association.Twitter!=null? < a href={association.Twitter}  target="_blank"><i className="icofont-twitter"></i></a>:''}

                            </li>
                            <li>
                            { association.Instagram!=null?     < a href={association.Instagram} target="_blank"><i className="icofont-instagram"></i></a>:''}

                           
                            </li>
                            <li>
                              { association.Linkedin!=null? <a href={association.Linkedin} target="_blank"><i className="icofont-linkedin"></i></a>:''}
                            </li>
                        </ul>
                    </div>
                </div></a> 
            </div>
          }))


        }
                      
                    </div>
                </div>
            </section>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(OrganisationsList);