import React from 'react';
import css from './LanguageSwitch.css';


import * as actionCreators from '../store/actions/actionCreators';
import {connect} from 'react-redux';
import {NavLink , Link} from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';


const LanguageSwitch = (props) => {
  function setPageDirection(language) {
    const dir = props.languageCode=="fr"? "rtl" : "ltr"
    document.documentElement.dir = dir
  }
  return (
    <div className="w-25 p-3">

      <Link 
                                                
                                                className="lang btn-primary" 
                                               onClick={() =>{
                                                if(props.languageCode=="fr"){
                                                  
                                                  props.langHandler("ar") 
                                                  setPageDirection("ar")
                                                
                                                } 
                                                  else{
                                                  props.langHandler("fr") 
                                                  setPageDirection("fr")
                                                }
                                                   }
                                                    }
                                            >
                                               {  props.languageCode=="fr"?'Ar':'Fr' }
                                            </Link>  
                                     
     </div>
  );
}

const mapStateToProps = state => {
  return {
    languageCode: state.languageCode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    
    langHandler: (langCode) => dispatch(actionCreators.changeLanguage(langCode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
