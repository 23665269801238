import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';
import Footer from '../Common/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, {useState,useEffect}  from "react"
import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";
import ModalImage from "react-modal-image";
import { LinkedinShareButton } from 'react-share';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
import ModalVideo from 'react-modal-video';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        768: {
            items:3,
        },
        1200: {
            items:5,
        }
    }
}

const strings = new LocalizedStrings(localeFile);
function  Organisationitem (props) {
    strings.setLanguage(props.languageCode);

    const [data, setData] = useState('');
    const [dataVideo, setdataVideo] = useState('');
    const [dataImage, setdataImage] = useState('');
    const [isOpen, setOpen] = useState(false)
    const [url, setUrl] = useState('')
    const [isOpenimg, setOpenimg] = useState(false)
    const [masterImage, setmasterImage] = useState('');
    const [modelIsOpen, setModalIsOpen] = useState(false)
    const [pathimage, setpathimage] = useState('')

    const { id } = useParams();

    useEffect(() => {
        async function getAssociation() {
           //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
            await axios.get(`https://admin.futurevisions.tn/api/get_associations/${id}`).then(({data}) => {
                setData(data.association);
                setdataImage(data.association.associaition_images)
                
                setdataVideo(data.association.associaition_videos)
        
            }).catch(({response}) => {
                // ida fama error chno n3mlo ! 
            
            })

            //   console.log(response.data.association.associaition_videos.length)
              

        }
        getAssociation() 
        {         dataImage.length != 0 && (dataImage.map((imag,index) => {
                    if(imag.master==1){
                        setmasterImage(imag)
                    }

          }))}
      }, []); 

 
        return (
            <React.Fragment>
                          <ModalVideo 
                    channel='youtube' 
                    autoplay
                    isOpen={isOpen} 
                    videoId={url} 
                    onClose={() => setOpen( false)} 
                    loading={() => <div>{strings.Loading}</div>}
                />
<ModalImage
isOpen={modelIsOpen}
onClose={()=>setModalIsOpen(false)}>

</ModalImage>
                <div className="page-title-area item-bg2">
                    <div className="container">
                        <h1>{strings.hreflangCode=='fr'? data.nom : data.nom_arabe}</h1>
                        <span> {strings.hreflangCode=='fr'? data.small_description :data.small_description_arabe}</span>
                        <ul>
                            {/* <li><Link to="/">{strings.Accueil}</Link></li>
                            <li>Organisation details</li> */}
                        </ul>
                    </div>
                </div>

                <section className="partner-area ptb-120">
                    <div className="container">

                    <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-details">
                                    <div className="post-image">
                                  
                 
          {  dataImage.length > 0 && (dataImage.map((im,index) => {
            return <img src={im.master=='1'?"https://admin.futurevisions.tn/"+im.image :""} height="500px"   />}))}
                                    </div>

                                    <h3>{strings.hreflangCode=='fr'?  data.nom :data.nom_arabe}</h3>

                                    <div className="blog-meta">
                                        <ul>
                                            <li><i className="icofont-location-pin"></i>{strings.hreflangCode=='fr'?  data.ville :data.ville_arabe}</li>
                                        </ul>
                                    </div>
                                    
                                    <p>{strings.hreflangCode=='fr'?  data.description : data.description_arabe }</p>

                                    <blockquote className="blockquote">
                                        <p> { strings.OBJECTIFS}</p>
                                    </blockquote>

                                    <p>{strings.hreflangCode=='fr'?  data.objectif :data.objectif_arabe}</p>
                                    {/* <blockquote className="blockquote">
                                        <p> {strings.CIBLE}</p>
                                    </blockquote>

                                    <p>{strings.hreflangCode=='fr'?  data.cible :data.cible_arabe}</p>
                                    <blockquote className="blockquote">
                                        <p> {strings.DOMAINE}</p>
                                    </blockquote>

                                    <p>{ strings.hreflangCode=='fr'?  data.domaine_activite : data.domaine_activite_arabe}</p> */}
    </div>
                                
                                <div className="post-tag-media">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-lg-6">
                                          
                                        </div>

                                      
                                    </div>
                                </div>

                               
                            </div>

                            <div className="col-lg-4">
                                <div className="sidebar">
                                  
                                    
                                    <div className="widget widget_categories">
                                        <h3 className="">
                                        {strings.Contact}
                                        </h3>
                                        {props.languageCode=='fr'?
                                       <ul>
                                            
                                       <li>  <i class="icofont-phone"></i> {data.numero_tel}</li>
                                       <li>   <i class="icofont-email"></i>{data.email_address}</li>
                                       <li> <a href={data.site_web}>  <i class="icofont-web"></i>{data.site_web}</a></li>

                                           <li> <i class="icofont-location-pin"></i> {data.adresse}</li>
                                           <li>
                                           {  data.Facebook== null ? ''  :<a href={ data.Facebook}><i className="icofont-facebook m-2"></i></a>}
                                           {  data.Twitter== null ? ''  :<a href={data.Twitter}><i className="icofont-twitter m-2"></i></a>}
                                              {   data.Instagram== null ? ''  :<a href={ data.Instagram}><i className="icofont-instagram m-2"></i></a>}
                                           {      data.Linkedin== null ? ''  : <a href={data.Linkedin}><i className="icofont-linkedin m-2"></i></a>}</li>
                                       </ul>:      <ul>
                                            
                                            <li> {data.numero_tel} <i class="icofont-phone"></i> </li>
                                            <li> {data.email_address}  <i class="icofont-email"></i></li>
                                            <li> <a href={data.site_web}> {data.site_web} <i class="icofont-web"></i></a></li>
     
                                                <li> {data.adresse} <i class="icofont-location-pin"></i></li>
                                                <li>
                                                {  data.Facebook== null ? ''  :<a href={ data.Facebook}><i className="icofont-facebook m-2"></i></a>}
                                                {  data.Twitter== null ? ''  :<a href={data.Twitter}><i className="icofont-twitter m-2"></i></a>}
                                                   {   data.Instagram== null ? ''  :<a href={ data.Instagram}><i className="icofont-instagram m-2"></i></a>}
                                                {data.Linkedin== null ? ''  : <a href={data.Linkedin}><i className="icofont-linkedin m-2"></i></a>}</li>
                                            </ul>   
                                    }
                                     
                                    </div>
                                    
                                
                                    
                                  
                                </div>
                            </div>
                        </div>






                        <div className="row">
                            <div className="col-lg-12">
                                <div className="partner-title platinum-sponsor">
                                    <PlatinumSponsors />
                                </div>
                            </div>

                            <OwlCarousel 
                                className="platinum-partner-slides owl-carousel owl-theme"
                              
                            >
                                              
                                              {         dataImage.length != 0 && (dataImage.map((imag,index) => {
            return       <div className="col-lg-12 col-md-12 ">
                                  <div className=" m-3">

                                  <img src={"https://admin.futurevisions.tn/"+imag.image} alt="Partner Logo" className='imgaeorgitem'  onClick={()=>{ setModalIsOpen(true)}} />

                         

                                </div>  
                                </div>
        

          }))}
                            </OwlCarousel>

                            <div className="col-lg-12">
                                <div className="border"></div>
                            </div>

                            <div className="col-lg-12">
                                <div className="partner-title gold-sponsor">
                                    <GoldSponsors />
                                </div>
                            </div>

                            <OwlCarousel 
                                className="gold-partner-slides owl-carousel owl-theme"
                          
                            >
                                
                              
                                {         dataVideo.length != 0 && (dataVideo.map((video,index) => {
            return       <div className="col-lg-12 col-md-12  ">
                                    <div className="m-3">
                                        <Link to="#"    onClick={()=>{ setOpen(true) ;setUrl(video.url)}}>
                                   
                                        <ImageWithPlayIcon src={"https://admin.futurevisions.tn/"+video.image} />
                                        <h4 className="widget-title text-center">
                                          { video.title}
                                        </h4>
                                    </Link>
                                    </div>
                                </div>
        

          }))}
                       

                              
                            </OwlCarousel>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    
}
 
 const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(Organisationitem);