import './assets/css/bootstrap.min.css';
import './assets/css/icofont.min.css';
import './assets/css/animate.min.css';
import './assets/css/style.css';
import '../node_modules/react-modal-video/css/modal-video.min.css'

// import './assets/css/responsive.css';

import React from 'react';
import AppRouter from './Routes';
import Preloader from './components/Shared/Preloader';
import LocalizedStrings from 'react-localization';
import localeFile from './local/content';
import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);

class App extends React.Component {
    state = {
        loading: true
    };

    componentDidMount(){
        this.demoAsyncCall().then(() => this.setState({ loading: false }));
    }

    demoAsyncCall = () => {
        return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    }
    constructor(props) {
        super(props);
        this.state = {
            isButtonClicked: false
        };
    }
    handleButtonClick = () => {
        this.setState({isButtonClicked: !this.state.isButtonClicked});
    }
    render(){    
        const dir = this.props.languageCode=="fr"? "ltr" : "rtl"
        document.documentElement.dir = dir
    
        return (
            <React.Fragment>
                
                {this.state.loading ? <Preloader /> : ''}
                <AppRouter  />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(App);