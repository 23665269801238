import React from 'react';
import lax from 'lax.js';
import LaxButton from '../Shared/LaxButton';
import axios from 'axios';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';

const strings = new LocalizedStrings(localeFile);
class About extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
        this.state = {
            posts: []
          };
       
        lax.update(window.scrollY)
    }
  
    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_about`)
          .then(res => {
            const posts = res.data.about[0];
            this.setState({ posts });
       
          })
      }
    render(){        strings.setLanguage(this.props.languageCode);

        return (
            <section className="about-area-two ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                {/* <span>Join The Event</span> */}
                                <h2><b>-</b> {strings.hreflangCode=='fr'? this.state.posts.presentation_title :this.state.posts.presentation_title_arabe} </h2>
                     
                                
                                <p>{strings.hreflangCode=='fr'? this.state.posts.presentation  :this.state.posts.presentation_arabe} </p>
                                {/* <div className="signature">
                                    <img src={require("../../assets/images/signature.png")} alt="about" />
                                </div> */}
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="about-image">
                                <img src={"https://admin.futurevisions.tn/"+this.state.posts.photo_presentation} className="about-img1" alt="about" />

                    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(About);