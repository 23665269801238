import React from 'react';
import { Link,NavLink } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import axios from 'axios';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class Footer extends React.Component {
    constructor(props) {
        super(props)

    
      
        this.state = {
            social: [],
            vision: []
          };
     
    }
    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/getSocial`)
          .then(res => {
            const social = res.data.social[0];
            this.setState({ social });
       
          })
          axios.get(`https://admin.futurevisions.tn/api/getVisions`)
          .then(res => {
            const vision = res.data.vision[0];
            this.setState({ vision });
       
          })
      }
    render(){
        strings.setLanguage(this.props.languageCode);
        return (
            <footer className="footer-area">
            
            <div className="copyright-area socialicons">
                    <div className='container-fluid row'>
                      <div className='col-md-4 mt-3 px-4' >
                        <h5> { strings.hreflangCode=='fr'?  this.state.social.Titre :this.state.social.Titre_arabe} </h5>
                        <p>{ strings.hreflangCode=='fr'?  this.state.social.description :this.state.social.description_arabe}</p>
      
                        < div className="d-flex justify-content-start ">
                          <div className='col-md-6' >
                          <i className="icofont-location-pin fa-2x mx-2 "></i> { strings.hreflangCode=='fr'?  this.state.social.adresse :this.state.social.adresse_arabe}
                          </div>
                          <div  className='col-md-6' >
                          <i className="icofont-phone fa-2x mx-2"></i>
                         
                          {   this.state.social.numero_tel }
                        

                          </div>
                        </div>
                        < div className="row d-flex justify-content-start pb-3 ">
                          <div className='col-md-6' >
                          <i className="icofont-email fa-2x mx-2"></i>
                     
                          { this.state.social.email_address }
                          </div>
                          <div  className='col-md-6' >
                          
                  <a href={ this.state.social.site_web} className='socialicons'>  <i className="icofont-web fa-2x mx-2"></i>{ this.state.social.site_web}</a>  
                          </div>
                        </div>
                     
                        <div className="d-flex justify-content-center ">
                        {  this.state.social.Facebook != null? <a  className='social' href=  {  this.state.social.Facebook }><i className="icofont-facebook fa-2x mx-2"></i></a>:''}      
                        {  this.state.social.Youtube !=''? <a  className='social' href=  {  this.state.social.Youtube }> <i className="icofont-youtube fa-2x mx-2"></i></a>:''}   
                          {  this.state.social.Linkedin !=''?<a className='social' href=  {  this.state.social.Linkedin }> <i className="icofont-linkedin fa-2x mx-2"></i></a>:''}   

                          {  this.state.social.Twitter !=''? <a className='social' href=  {  this.state.social.Twitter }> <i className="icofont-twitter fa-2x mx-2"></i></a>:''}   
                          {  this.state.social.Instagram !=''? <a className='social' href=  {  this.state.social.Instagram }> <i className="icofont-instagram fa-2x mx-2"></i> </a>:''}   
                          
                                                 </div>
                      </div>
                      <div  className='col-md-4 mt-5 p-0 ' >
                
                      
                      
                          <div  className='  p-0 m-0' >
                          <img src={require("../../assets/images/footer_Plan de travail 1-01.png")} alt="Sponsor1" />
                          </div>
                         
                          
                      
                       
                      </div>
                      <div className='col-md-4 mt-3 px-4 ' >
                        <h5>
                        { strings.hreflangCode=='fr'?  this.state.vision.Titre :this.state.vision.Titre_arabe}

                        </h5>
                        <p>

                        { strings.hreflangCode=='fr'?  this.state.vision.description :this.state.vision.description}
                        </p>
      
                        < div className="d-flex justify-content-start ">
                          {/* <div className='col-md-6' >
                          <i className="icofont-location-pin fa-2x mx-2 "></i> rue code postal ville pays
                          </div> */}
                          {/* <div  className='col-md-6' >
                          <i className="icofont-phone fa-2x mx-2"></i>
                          +216 55 551 112
                        

                          </div> */}
                        </div>
                        < div className="d-flex justify-content-center pb-2 ">
                        {  this.state.vision.Facebook != null?      <div className='col-md-6' >
                          <i className="icofont-email fa-2x mx-2"></i>

                          {  this.state.vision.email_address }
                          </div>:''}      

                     
                          {/* <div  className='col-md-6' >
                          <i className="icofont-web fa-2x mx-2"></i>
                          colorlib.com/wp/bootstrap-footer/

                          </div> */}
                        </div>
                     
                        <div className="d-flex justify-content-center ">
                        {  this.state.vision.Facebook != null? <a className='social' href=  {  this.state.vision.Facebook }><i className="icofont-facebook fa-2x mx-2"></i></a>:''}      
                        {  this.state.vision.Youtube != null? <a className='social' href=  {  this.state.vision.Youtube }> <i className="icofont-youtube fa-2x mx-2"></i></a>:''}   
                          {  this.state.vision.Linkedin != null?<a className='social' href=  {  this.state.vision.Linkedin }> <i className="icofont-linkedin fa-2x mx-2"></i></a>:''}   

                          {  this.state.vision.Twitter != null? <a className='social' href=  {  this.state.vision.Twitter }> <i className="icofont-twitter fa-2x mx-2"></i></a>:''}   
                          {  this.state.vision.Instagram != null? <a className='social' href=  {  this.state.vision.Instagram }> <i className="icofont-instagram fa-2x mx-2"></i> </a>:''}   
                          
                                                 </div>
                      </div>
                    </div>
                    <div className='mt-3'>
                    <p>
                          Copyright <i className="icofont-copyright"></i> 2023 {strings.FutureVisions}. {strings.All_rights_reserved}
                           </p>
                    </div>
      </div>
                     
                
                       
               
              
            </footer>
        );
    }
}
 
const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(Footer);