import React from 'react'
import MainBanner from '../AboutTwo/MainBanner';
import About from '../AboutTwo/About';
import WhyUs from '../AboutTwo/WhyUs';
import Organisations from '../HomeTwo/Organisations';
import Gallery from '../Common/Gallery';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
 
class AboutTwo extends React.Component {
    render(){
        return (
            <React.Fragment>

                <MainBanner />

                <About />

                <WhyUs />
         
                {/* <Organisations /> */}
         
                <Gallery />
            
                <Footer />
                {/* Back Top top */}
                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default AboutTwo;