import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';
import axios from 'axios';
import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";
import ModalVideo from 'react-modal-video';
import   {AiOutlinePlayCircle} from 'react-icons/ai';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';

const strings = new LocalizedStrings(localeFile);
const options = {
    nav: false,
    dots: true,
    margin:8 ,  
     startPosition:1 ,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:1,
        },
        400: {
            items:1,
        },
        576: {
            items:2,
        },
        768: {
            items:2,
        },
        1200: {
            items:3,
        }
    }
}
 
class Partner extends React.Component {
 
  
    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/api/get_about`)
          .then(res => {
            const posts = res.data.about[0];
            this.setState({ posts });
            if(posts){            this.setState( {dataImage:res.data.about[0].about_images} );
        }
        this.setState({dataVideo:res.data.about[0].about_videos})

         
          }).catch(({response}) =>{
        

          })
      }
    constructor(props) {
        super(props)
        lax.setup()

        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
        this.state = {
            posts: [],

            dataVideo:[],
            dataImage:[],
            isOpen:false,
            url:''
          };
        lax.update(window.scrollY)
    }
    openModal = () => {
        this.setState({isOpen: true})
    }
    render(){        strings.setLanguage(this.props.languageCode);

        return (
            <section className="partner-area ptb-120">
                  <ModalVideo 
                    channel='youtube' 
                    autoplay
                    isOpen={   this.state.isOpen} 
                    videoId={this.state.url} 
                    onClose={() =>   this.setState({ isOpen :false}) } 
                    loading={() => <div>Loading...</div>}
                />
                <div className="container">
                    <div className="section-title">
                        {/* <span>Check Who Makes This Event Possible!</span> */}
                        <h2> <b>{strings.Galerie}</b></h2>

                        {/* <Link to="/sponsors" className="btn btn-primary">Become Link Sponsor</Link> */}

                        <div className="bar"></div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="partner-title platinum-sponsor">
                                 <PlatinumSponsors />
                            </div>
                        </div>
   
                        <OwlCarousel className="carsoul" {...options}  > 

                        {           this.state.dataImage.length != 0 && (  this.state.dataImage.map((imag,index) => {
            return      <div className="col-lg-12 col-md-12 ">
            <div className=" m-3">
            <img key={index}  className="img" src={"https://admin.futurevisions.tn/"+imag.image} alt="Partner Logo" />

          </div>  
          </div>
                       
        

          }))}
                         
         </OwlCarousel>  



                        <div className="col-lg-12">
                                <div className="border"></div>
                            </div>

                            <div className="col-lg-12">
                                <div className="partner-title gold-sponsor">
                                    <GoldSponsors />
                                </div>
                            </div>
      

<div  className='container-fluid'>
                            <OwlCarousel className="carsoul" {...options} >
                                
                              
                                {           this.state.dataVideo.length != 0 && ( this.state.dataVideo.map((video,index) => {
            return       <div className="col-lg-12 col-md-12  ">
            <div className="author-vd  m-3">
              
              <Link to="#"   className="img"   onClick={()=>{  this.setState({isOpen:true}) ; this.setState({url:video.url})}}>
                         
                         <ImageWithPlayIcon src={"https://admin.futurevisions.tn/"+video.image} />
                         <h4 className="widget-title text-center">
                                                { video.title}
                                              </h4>
                     </Link>
        
      
                     </div>
      
                                </div>
        
      
          }))}
                       

                              
                            </OwlCarousel></div>
                    </div>
                </div>
            </section>
        );
    }
}
 


const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(Partner);