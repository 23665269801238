import React from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';
import {connect} from 'react-redux';

const strings = new LocalizedStrings(localeFile);
class MainBanner extends React.Component {
    render(){
        strings.setLanguage(this.props.languageCode);

        return (
            <div className="page-title-area item-bg2">
                <div className="container">
                    <h1>  {strings.a_propos}</h1>
                    {/* <span>How we are ?</span> */}
                    {/* <ul>
                        <li><Link to="/">{strings.ACTUALITÉS}</Link></li>
                        <li>{strings.a_propos}</li>
                    </ul> */}
                </div>
            </div>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }

export default connect(mapStateToProps)(MainBanner);