import { Link } from 'react-router-dom';
import Footer from '../Common/Footer';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import React, {useState,useEffect}  from "react"
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import { withTranslation } from 'react-i18next';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
import { LinkedinShareButton  ,
    
  FacebookShareButton,

  TwitterShareButton,
 
  




} from 'react-share';
const strings = new LocalizedStrings(localeFile);
function BlogDetails(props)  {    strings.setLanguage(props.languageCode);

    const [data, setData] = useState('');
    const [categories, setcategories] = useState([]);
    const [posts, setposts] = useState([]);
    const [imagespost, setimagespost] = useState([]);
    const { id } = useParams()
    const [dateObject, setDateObject] = useState(null);
    // const [t,i18n] = useTranslation();

    useEffect(() => {
        async function getPost() {
           //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
               await axios.get(`https://admin.futurevisions.tn/api/get_post/${id}`)
              .then(res => {
                const post = res.data.post;

             
                setData(post);
                 setimagespost(post.post_images);
    
              })

        }
        getPost() 

        // console.log(data.post_images[0].image)
      }, []); 

      useEffect(()=> {
        async function getCategories() {
           //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
              const response = await axios.get('https://admin.futurevisions.tn/api/get_categories');
              setcategories(response.data.category);

              

        }
        getCategories() 
      
      }, []);

      useEffect(()=> {
        async function getPosts() {
           //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
              const response = await axios.get('https://admin.futurevisions.tn/api/get_posts');
              setposts(response.data.post);

              

        }
        getPosts() 
      }, []);
    //   useEffect(() => {
    //     if (data) {
    //       const dateObject = new Date(data.created_at);
    //       setDateObject(dateObject);
    //     }
    //   }, [data]);
   
      
        return (
            
            <React.Fragment>
                          {  imagespost.length > 0 && (imagespost.map((im,index) => {
             if(im.presentation=='1'){   return  <div className="page-title-area" style={{  
                backgroundImage: "url(" + "https://admin.futurevisions.tn/"+im.image + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
              }}>
                         <div className="container">
                             <h1>{ strings.hreflangCode=='fr'?  data.titre :data.titre_arabe}</h1>
            
                           </div>
                     </div>}
          
  }))}
               

                <section className="blog-details-area ptb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-details">
                            
                             

                                    <h3>{strings.hreflangCode=='fr'?  data.titre :data.titre_arabe}</h3>

                                    <div
      dangerouslySetInnerHTML={{__html: strings.hreflangCode=='fr'? data.body :data.body_arabe}}
    />
        

                     </div>
                                
                                <div className="post-tag-media">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-lg-6">
                                          
                                        </div>

                                        <div className="col-lg-6">
                                            <ul className="social-share">
                                                <li><span> {strings.PARTAGER_SUR}</span></li>
                                                <li>
                                                    
                                               
                                                    <FacebookShareButton 
            url={`https://visio.futurevisions.tn/single-blog/${id}`}
        >
                <i className="icofont-facebook"></i>

        </FacebookShareButton>
                                                        
                                                    
                                                    </li>
                                                <li>
                                                <TwitterShareButton 
            url={`https://visio.futurevisions.tn/single-blog/${id}`}
            title={data.titre}
            hashtags={data.titre}
        >

                                                    <i className="icofont-twitter"></i>
                                                    </TwitterShareButton> 
                                                    </li>
                                             
                                                <li>  <LinkedinShareButton 
            url={`https://visio.futurevisions.tn/single-blog/${id}`}
            title={data.titre}
        ><i className="icofont-linkedin"></i></LinkedinShareButton> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                               
                            </div>

                            <div className="col-lg-4">
                                <div className="sidebar">
                                  
                                    
                                    <div className="widget widget_categories">
                                        <h3 className="">
                                        {strings.Catégories}
                                        </h3>

                                        <ul>
                                            {       categories.length != 0 && (categories.map((category,index) => {
                                                          return <li><Link to={`/blog/id=${category.id}`}>{strings.hreflangCode=='fr'? category.name : category.name_arabe}</Link></li>
        
                                                          }))}
                                        </ul>
                                    </div>
                                    
                                    <div className="widget widget_recent_entries">
                                        <h3 className="">
                                        {strings.a_LIRE_AUSSI}                                        </h3>

                                        <ul>

                                        {       posts.length != 0 && (posts.map((post,index) => {
                                                          return post.id!=data.id?     <li>
                                                             {  post.post_images.length > 0 && (post.post_images.map((im,index) => {
            return    <Link to="#"> <img src={im.master=='1'?"https://admin.futurevisions.tn/"+im.image :""}  alt="blog"/> </Link>}))}

                                                       
                                                         
          
                                                          <h5><Link to={`/single-blog/id=${post.id}`}>{strings.hreflangCode=='fr'? post.titre : post.titre_arabe }</Link></h5>
                                                          <p className="date"><SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{post.updated_at}</SimpleDateTime></p>
                                                                                     </li>:''
        
                                                          }))}
                                       

                                        
                                        </ul>
                                    </div>
                                    
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    
}
 


const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(BlogDetails);