import React from 'react';
import ModalVideo from 'react-modal-video';
import axios from 'axios';
import ImageWithPlayIcon from "../Shared/ImageWithPlayIcon";

import { Link } from 'react-router-dom';
import   {AiOutlinePlayCircle} from 'react-icons/ai';





import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class EventSchedulesFour extends React.Component {

  
    constructor(props) {
        super(props)
       
        this.state = {
           resources: [],
           resourcespdfs: [],
           resourcesvideos: [],
           resourcespodcasts: [],
           isOpen:false,
           url:''
          };
     
    }

    componentDidMount(){
        axios.get(`https://admin.futurevisions.tn/pi/get_ressources`)
          .then(res => {
            const resources = res.data.resource;
       
            this.setState({ resources :resources});
     
        
          })
        

      }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }
    openModal = () => {
        this.setState({isOpen: true})
    }

    render(){strings.setLanguage(this.props.languageCode);
        return (
            
            <section className="schedule-area schedule-style-four bg-image ptb-120">
                         <ModalVideo 
                    channel='youtube' 
                    autoplay
                    isOpen={   this.state.isOpen} 
                    videoId={this.state.url} 
                    onClose={() =>   this.setState({ isOpen :false}) } 
                    loading={() => <div>{ strings.Loading }</div>}
                />
                <div className="container">
                    <div className="section-title">
                     
                        <div className="bar"></div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <div className="row">
                                {
        strings.hreflangCode=='ar'?  <div className="col-lg-4 col-md-3">
        <ul className="tabs active">
            <li
                onClick={(e) => this.openTabSection(e, 'tab1')}
                className="current"
            >
                <Link to="#">
                {strings.GUIDE}

                   
                </Link>
            </li>

            <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                <Link to="#">
                {strings.podcast}
                 
                </Link>
            </li>

            <li onClick={(e) => this.openTabSection(e, 'tab3')}>
                <Link to="#">
                {strings.video}

              
                </Link>
            </li>

            {/* <li onClick={(e) => this.openTabSection(e, 'tab4')}>
                <Link to="#">
                Ressource type 3
                </Link>
            </li> */}
        </ul>
    </div>:''
    }       
        
                                    <div className="col-lg-8 col-md-9">
                                        <div className="tab_content">



    <div id="tab1" className="tabs_item">
                                        <ul className="accordion">
                                             {     this.state.resources.length > 0 && (   this.state.resources.map((resource,index) => {
                                                    if(resource.urlpdf !=null) { return <li className="accordion-item">
            <div className="accordion-title">
                <div className="author">
                    <img 
                        src={"https://futurevisions.tn/ranyalakoud/public/"+resource.image }
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="Steven Smith" 
                        alt="Schedule" 
                    />
                </div>
                
                <div className="schedule-info mx-3">
                    <h3>{strings.hreflangCode=='fr'?  resource.titre : resource.titre_arabe}</h3>
                      
                            
                            <ul>
                        <li><a href={"https://futurevisions.tn/ranyalakoud/public/"+ resource.urlpdf }  download>

                        <span>{ strings.Télécharger }</span>
                        <i className="icofont-download"></i></a>
                        </li>
                       
                    </ul>
                </div>
            </div>
            
      
        </li>
              }

                
         
    
            }))}     </ul>	  </div> 



            <div id="tab2" className="tabs_item">
            <ul className="accordion">
               
              {     this.state.resources.length > 0 && (   this.state.resources.map((podcast,index) => {
           if(podcast.podcast !=null) { return  <li className="accordion-item">
            <Link className="accordion-title" to="#">
                <div className="author-vd">
              
                        <Link to="#"    onClick={()=>{  this.setState({isOpen:true}) ; this.setState({url:podcast.video})}}>
                                   
                                   <ImageWithPlayIcon src={"http://admin.futurevisions.tn/"+podcast.image} />
                                 
                               </Link>
                    <div className='icon-play-vd'>
                  <AiOutlinePlayCircle  size={'50px'}/>
                    </div>


                </div>
                
                <div className="schedule-info mx-3">
                    <h3>{strings.hreflangCode=='fr'?  podcast.titre : podcast.titre_arabe}</h3>

                    {/* <ul>
                        <li>
                            <i className="icofont-user-suited"></i> 
                            By <span>Steven Smith</span> CEO of EnvyTheme
                        </li>
                        <li>
                            <i className="icofont-wall-clock"></i> 13:00AM - 20:00PM
                        </li>
                    </ul> */}
                </div>
            </Link>
          
        </li>
              
            }

                
         
    
            }))}
              
            </ul>	
        </div>  
        
        <div id="tab3" className="tabs_item">
            <ul className="accordion">
          
                {     this.state.resources.length > 0 && (   this.state.resources.map((resource,index) => {
           if(resource.video !=null) { return <li className="accordion-item">
            <Link className="accordion-title" to="#">
                <div className="author-vd">
              
                        <Link to="#"    onClick={()=>{  this.setState({isOpen:true}) ; this.setState({url:resource.video})}}>
                                   
                                   <ImageWithPlayIcon src={"http://admin.futurevisions.tn/"+resource.image} />
                                 
                               </Link>
                    <div className='icon-play-vd'>
                  <AiOutlinePlayCircle  size={'50px'}/>
                    </div>


                </div>
                
                <div className="schedule-info mx-3">
                    <h3>{strings.hreflangCode=='fr'?  resource.titre : resource.titre_arabe}</h3>

                    {/* <ul>
                        <li>
                            <i className="icofont-user-suited"></i> 
                            By <span>Steven Smith</span> CEO of EnvyTheme
                        </li>
                        <li>
                            <i className="icofont-wall-clock"></i> 13:00AM - 20:00PM
                        </li>
                    </ul> */}
                </div>
            </Link>
          
        </li>

                
}

    
            }))}
            </ul>	
        </div>             

                                           

                                            {/* <div id="tab4" className="tabs_item">
                                                <ul className="accordion">
                                                <li className="accordion-item">
                                                        <Link className="accordion-title" to="#">
                                                            <div className="author">
                                                                <img 
                                                                    src={require("../../assets/images/author1.jpg")} 
                                                                    data-toggle="tooltip" 
                                                                    data-placement="top" 
                                                                    title="Steven Smith" 
                                                                    alt="Schedule" 
                                                                />
                                                            </div>
                                                            
                                                            <div className="schedule-info">
                                                                <h3>Guide pratique pour les organisations de jeunesse en Algérie</h3>
                                                                  
                                                                        
                                                                        <ul>
                                                                    <li>
                                                                    <span>Télécharger</span>
                                                                    <i className="icofont-download"></i>
                                                                    </li>
                                                                   
                                                                </ul>
                                                            </div>
                                                        </Link>
                                                        
                                                     
                                                    </li>
                                                </ul>	
                                            </div> */}
                                        </div>
                                    </div>
                                    {
        strings.hreflangCode=='fr'?  <div className="col-lg-4 col-md-3">
        <ul className="tabs active">
            <li
                onClick={(e) => this.openTabSection(e, 'tab1')}
                className="current"
            >
                <Link to="#">
                {strings.GUIDE}

                   
                </Link>
            </li>

            <li onClick={(e) => this.openTabSection(e, 'tab2')}>
                <Link to="#">
                {strings.podcast}
                 
                </Link>
            </li>

            <li onClick={(e) => this.openTabSection(e, 'tab3')}>
                <Link to="#">
                {strings.video}

              
                </Link>
            </li>

            {/* <li onClick={(e) => this.openTabSection(e, 'tab4')}>
                <Link to="#">
                Ressource type 3
                </Link>
            </li> */}
        </ul>
    </div>:''
    }       
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

















                

                <div className="shape1">
                    <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
                </div>
                <div className="shape2 rotateme">
                    <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
                </div>
                <div className="shape3 rotateme">
                    <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
                </div>
                <div className="shape4">
                    <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
                </div>
            </section>
        );
    }
}
 
 const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(EventSchedulesFour);