import React from 'react';
import MainBanner from '../OrganisationsList/MainBanner';
import OrganisationsList from '../OrganisationsList/OrganisationsList';
import Footer from '../Common/Footer';
import LocalizedStrings from 'react-localization';
import localeFile from '../../local/content';

import {connect} from 'react-redux';
const strings = new LocalizedStrings(localeFile);
class SpeakersThree extends React.Component {
    render(){ strings.setLanguage(this.props.languageCode);
        return (
            <React.Fragment>
                <MainBanner />
                <OrganisationsList />
                <Footer />
            </React.Fragment>
        );
    }
}
 

const mapStateToProps = state => {
    return {
      languageCode: state.languageCode
    }
  }
  
  export default connect(mapStateToProps)(SpeakersThree);