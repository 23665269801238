const content = {
  fr:{
    hreflangCode: "fr",
    baseUrl: "http://ellevoro-restaurant.tk/",
    En_savoir_plus:"En savoir plus",
    Organisations_Members:"Done by CSOs",
    Voir_tous_les_membres:'Voir tous',
    ACTUALITÉS:"DATAVIZ",
    Nos_dernières_nouvelles:'Nos dernières nouvelles',
    Voir_toutes_les_nouvelles:'Voir tous',
    Accueil:'ACCUEIL',
     a_propos:'CIRCLES',
     ORGANISATIONS :'CSOs ',    
     ORGANISATIONSdescrip :'     ORGANISATIONS ',
     RESSOURCES:'RESSOURCES',
     a_PROPOS_DE_NOUS:'À PROPOS DE NOUS',
     Galerie:'Galerie',
     Galerie_Des_Photos:'Galerie Des Photos',
     Galerie_Des_Videos:'Galerie Des Videos',
     GUIDE:'GUIDE',
     podcast:'podcast',
     video:'Video',
     regarderz_video_promo:'Regarder video promo',
     Télécharger:'Télécharger',
     Loading:'Chargement ...',
     Top:'Top',
     Contact :'CONTACT ',
     CONTACTUS:'Contactez-nous',

     OBJECTIFS:'OBJECTIFS',
     CIBLE :'CIBLE ',
     DOMAINE:'DOMAINE',
     Catégories:'Catégories',
     a_LIRE_AUSSI:'À LIRE AUSSI',
     PARTAGER_SUR:'PARTAGER SUR',
     All_rights_reserved:'Tous droits réservés',
     envoyermessage:'Votre message a été envoyé',
     merci:'Merci',
     envoyer:'Envoyer',
     Name:'Nom',
     Email:'Email',
     Phone:'Numéro de téléphone ',
     Message:'Message',
     FutureVisions:'Future Visions',
     Round:'ROUND '
     
  },
  ar: 
  {
    hreflangCode: "ar",
    baseUrl: "http://ellevoro-restaurant.tk/",
    En_savoir_plus:" اقرأ المزيد",
    Organisations_Members :" الجمعيات",
    Voir_tous_les_membres:'عرض الكل ',
    ACTUALITÉS:"رسوم بيانية",
    Nos_dernières_nouvelles:'آخر أخبارنا',
    Voir_toutes_les_nouvelles:'عرض كل الأخبار',
    Accueil:'الرئيسية ',
    a_propos:' حلقات الرؤى',
    ORGANISATIONS :'الجمعيات',
    RESSOURCES:'الموارد',
    a_PROPOS_DE_NOUS:'معلومات عنا',
    Galerie:'المعرض',
    Galerie_Des_Photos:'معرض الصور',
    Galerie_Des_Videos:'معرض الفديوهات',
    GUIDE:'دليل',
    podcast:'بودكاست',
    video:'الفديوهات',
    regarderz_video_promo:' مشاهدة الفيديو الترويجي',
    Télécharger:'تحميل',
    Loading:'... جار التحميل',
    Top:'صعود',
    Contact :'للتواصل',
    OBJECTIFS:'اهدافها',
    CIBLE :'تستهدف',
    DOMAINE:'الإختصاص',
    Catégories:'الفئات',
    a_LIRE_AUSSI:
    'اقرأ أيضا',
    PARTAGER_SUR:'شارك على',
    All_rights_reserved:'جميع الحقوق محفوظة',
    CONTACTUS:'تواصل معنا',
    envoyermessage:'تم إرسال الرسالة بنجاح',
    merci:'شكرا',
    envoyer:'إرسال',
    Name:': الإسم',
    Email:': البريد الإلكتروني ',
    Phone:': رقم الهاتف ',
    Message:': الرسالة ',
    FutureVisions:'رؤى المستقبل',
    Round:'جولة الرؤى',
    ORGANISATIONSdescrip:'الجمعيات '
  
  }
}

export default content;
